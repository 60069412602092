<template>
  <div class="field">
    <label class="label">{{label}}</label>
    <div class="control">
      <autocomplete
        :search="search"
        placeholder="Search directory or enter a name"
        aria-label="Search directory or enter a name"
        :get-result-value="getResultValue"
        @submit="onSubmit"
      >
      </autocomplete>
    </div>
    <p
      v-if="error"
      v-html="formatErrorMsg()"
      class="help is-danger"
    >
    </p>
    <p
      v-if="market.dmi_id && market.name.length"
      class="is-size-7"
      style="margin-top: 10px;"
    >
      Welcome from the <a :href="market.url" target="_blank">{{ market.name }}</a> Discovery Map&reg;! We'll auto-fill your business details.
    </p>
  </div>
</template>

<script>
import dmiApi from "@/service/dmiApi.js";
import Autocomplete from '@trevoreyre/autocomplete-vue'

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    validation: {
      type: Object,
      required: false
    }
  },
  components: {
    Autocomplete,
  },
  data() {
    return {
      input: null,
      business: {
        name: '',
        image: '',
        phone: '',
        about: '',
        website: '',
        formatted_address: '',
        award_method: 'both'
      },
      market: {
        dmi_id: null,
        name: '',
        slug: '',
        url: ''
      }
    }
  },
  computed: {
    error: function() {
      return (this.validation && this.validation.$error) ? this.validation.$error : false
    }
  },
  methods: {
    formatErrorMsg() {
      return (this.error) ? "input not valid" : ""
    },
    search(input) {
      return new Promise(resolve => {
        this.input = input
        this.$emit('input', this.input)

        if (input.length < 3) {
          return resolve([])
        }
        dmiApi.getBusinesses(input)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          console.log("There was an error:", error.response);
        })
      })
    },
    clearBusiness() {
      return {
        name: '',
        image: '',
        phone: '',
        about: '',
        website: '',
        formatted_address: '',
        award_method: 'both'
      }
    },
    clearMarket() {
      return {
        dmi_id: null,
        name: '',
        slug: '',
        url: ''
      }
    },
    getResultValue(result) {
      return (typeof result === 'object' && result !== null) ? result.attributes["business-name"] : null
    },
    async onSubmit(result) {
      if (result !== null && typeof result === 'object') {
        this.business.name = result.attributes["business-name"]
        this.business.about = strip_html(result.attributes["description"])
        let img = format_img(result.attributes["web-image-url"])
        this.business.image = img
        this.business.formatted_address = result.attributes["full-address"]

        // fetch phone & website
        await dmiApi.getBusinessPhoneNumbers(result.id)
          .then(response => {
            if (response.data && response.data.data && response.data.data.length) {
              this.business.phone = response.data.data[0].attributes["phone"]
            }
          })
          .catch(error => {
            console.log(`There was an error fetching phone numbers for DMI business #${result.id}:`, error.response)
          })
        await dmiApi.getBusinessWebUrls(result.id)
          .then(response => {
            if (response.data && response.data.data && response.data.data.length) {
              this.business.website = response.data.data[0].attributes["url"]
            }
          })
          .catch(error => {
            console.log(`There was an error fetching URLs for DMI business #${result.id}:`, error.response)
          })

        // fetch the destination/market
        await dmiApi.getBusinessDestination(result.id)
          .then(response => {
            if (response.data && response.data.data && response.data.data.attributes) {
              const marketData = response.data.data
              this.market.dmi_id = marketData.id
              this.market.name = marketData.attributes.name
              this.market.slug = market_url_to_slug(marketData.attributes.url)
              this.market.url = marketData.attributes.url
            } else {
              this.market = this.clearMarket()
              console.log(`The fetched destination for DMI business #${result.id} was invalid!`)
            }
          })
          .catch(error => {
            this.market = this.clearMarket()
            console.log(`There was an error fetching the destination for DMI business #${result.id}:`, error.response)
          })
      } else {
        this.business = this.clearBusiness()
        this.market = this.clearMarket()
        this.business.name = this.input
      }
      this.$parent.updateBusiness(this.business)
      this.$emit('input', this.business.name)
    }
  }
};

function strip_html(html) {
  let div = document.createElement("div");
  div.innerHTML = html;
  let text = div.textContent || div.innerText || "";
  return text
}

function format_img(img) {
  if (img != null && img.substr(0,1) === "/") {
    return "https://discoverymap.com" + img
  } else {
    return img
  }
}

function market_url_to_slug(url) {
  let matches
  if (url != null && (matches = url.match(/^https?:\/\/(?:\w+\.)?discoverymap\.com\/([a-z0-9-]+)\/?$/i))) {
    return matches[1]
  } else {
    return ''
  }
}

</script>


