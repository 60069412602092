import {AuthError, NetworkError} from "@/errors";

export default {
  computed: {
    currentMarket() {
      return (this.$store.getters.currentMarket)
    },
    referringMarket() {
      return (this.$route.query.m && this.$route.query.m > 0) ? this.$route.query.m : null
    }
  },
  methods: {
    updateMarketReferral() {
      if (this.referringMarket) {
        const params = {
          dmi_id: this.referringMarket
        }
        this.$store.dispatch("getMarket", params)
          .catch(err => {
            if (err instanceof AuthError || err instanceof NetworkError) {
              throw err
            } else {
              console.error(err)
              this.error = err
            }
          })
      }
    }
  }
}
